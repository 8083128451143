import React from "react"
import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"

export default function ProgressDialog(props: { isVisible: boolean }) {
  return (
    <>
      <Modal centered={true} backdrop="static" show={props.isVisible}>
        <Modal.Body>
          <div className="ProgressDialog">
            <Spinner animation="border" role="status">
              <span className="sr-only"> Processing Payment...</span>
            </Spinner>
            <div className="ProgressDialogText">Processing Payment...</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
