import parse from "html-react-parser"
import React, { useEffect, useState } from "react"

import AdminDashboard from "./AdminDashboard"
import Footer from "./Footer"
import FrontPageBox from "./FrontPageBox"
import Header from "./Header"
import ImportUsersForm from "./ImportUsersForm"
import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import log from "./log"
import StripeProviderBox from "./StripeProviderBox"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"
import { TaxonomyDataParser } from "./taxonomy/TaxonomyDataParser"

type FunctionComponent = (props: unknown) => JSX.Element | null

const components: { [key: string]: FunctionComponent } = {
  AdminDashboard,
  FrontPageBox,
  ImportUsersForm,
  StripeProviderBox,
}

function getComponentByName(componentName: string): FunctionComponent {
  if (Object.keys(components).includes(componentName)) {
    return components[componentName]
  } else {
    throw new Error(
      `Component name "${componentName}" not found in the "components" list of ApplicationBodyContent.`
    )
  }
}

export default function ApplicationBodyContent(props: {
  alert: string | null
  images: KlimekReviewsImageUrls
  contentComponentName?: string
  contentComponentProperties?: { [key: string]: unknown }
  childrenHtml?: string
  current_user: {
    first_name: string
    is_admin: boolean
    is_premium: boolean
    last_name: string
  } | null
  notice: string | null
  user_signed_in: boolean
}) {
  const [answeredQuestionsCountByTopic, setAnsweredQuestionsCountByTopic] =
    useState<Record<number, number> | null>(null)
  const [categories, setCategories] = useState<
    CategoryWithTopicsAndBookmarkCount[] | null
  >(null)

  useEffect(function () {
    if (props.current_user && props.current_user.is_premium) {
      fetchCategories()
      fetchAnsweredQuestionsCountByTopic()
    }
  }, [])

  if (answeredQuestionsCountByTopic && categories) {
    combineData(null, null)
  }

  function prepareComponentProperties(
    componentName: string,
    componentProperties: {
      [key: string]: unknown
    }
  ): { [key: string]: unknown } {
    if (componentName === "FrontPageBox") {
      return {
        ...componentProperties,
        categories,
        onAnsweredQuestionsCountByTopicReceived(
          answeredQuestionsCountByTopic: Record<number, number>
        ): void {
          setAnsweredQuestionsCountByTopic(answeredQuestionsCountByTopic)
          combineData(null, answeredQuestionsCountByTopic)
        },
        onCategoriesReceived(
          categories: CategoryWithTopicsAndBookmarkCount[]
        ): void {
          setCategories(categories)
          combineData(categories, null)
        },
      }
    } else {
      return componentProperties
    }
  }

  async function fetchCategories() {
    log(ApplicationBodyContent, "fetchCategories")

    const response = await fetch("/api/bookmarks?categorize=true")
    if (response.ok) {
      const responseText = await response.text()

      const categories =
        TaxonomyDataParser.parseCategoriesWithTopicsAndBookmarkCounts(
          responseText
        )

      console.log("setting categories:", categories)
      setCategories(categories)
      combineData(categories, null)
    } else {
      console.error("Failed to fetch Categories and Topics.")
    }
  }

  async function fetchAnsweredQuestionsCountByTopic() {
    log(ApplicationBodyContent, "fetchAnsweredQuestionsCountByTopic")

    const response = await fetch("/api/answering_questions/countByTopic")
    if (response.ok) {
      const responseText = await response.text()

      const answeredQuestionsCountByTopic =
        TaxonomyDataParser.parseAnsweredQuestionsCountByTopic(responseText)

      console.log(
        "setting answeredQuestionsCountByTopic:",
        answeredQuestionsCountByTopic
      )
      setAnsweredQuestionsCountByTopic(answeredQuestionsCountByTopic)
      combineData(null, answeredQuestionsCountByTopic)
    } else {
      console.error("Failed to fetch answered questions count by topic.")
    }
  }

  function combineData(
    categoriesParameter: CategoryWithTopicsAndBookmarkCount[] | null,
    answeredQuestionsCountByTopicParameter: Record<number, number> | null
  ) {
    console.log(
      "combineData",
      categoriesParameter,
      answeredQuestionsCountByTopicParameter
    )

    const _categories = categoriesParameter || categories
    const _answeredQuestionsCountByTopic =
      answeredQuestionsCountByTopicParameter || answeredQuestionsCountByTopic

    if (_categories && _answeredQuestionsCountByTopic) {
      console.log("combineData combining...")
      for (const category of _categories) {
        for (const topic of category.topics) {
          topic.answeredQuestionsCount =
            _answeredQuestionsCountByTopic[topic.topic_id]
          console.log(
            "topic.answeredQuestionsCount: " + topic.answeredQuestionsCount
          )
        }
      }
    } else {
      console.log("combineData not combining. categories", _categories)
      console.log(
        "combineData not combining. answeredQuestionsCountByTopic",
        _answeredQuestionsCountByTopic
      )
    }
  }

  function createComponent() {
    if (props.contentComponentName === undefined) {
      throw new Error(`Property contentComponentName is undefined.`)
    }

    if (props.contentComponentProperties === undefined) {
      throw new Error(`Property contentComponentProperties is undefined.`)
    }

    return React.createElement(
      getComponentByName(props.contentComponentName),
      prepareComponentProperties(
        props.contentComponentName,
        props.contentComponentProperties
      )
    )
  }

  return (
    <>
      <div className="content">
        <Header
          categories={categories}
          images={props.images}
          current_user={props.current_user}
          user_signed_in={props.user_signed_in}
          user_is_premium={
            !!(props.current_user && props.current_user.is_premium)
          }
        />
        {props.notice ? (
          <p className="alert alert-info">{props.notice}</p>
        ) : null}
        {props.alert ? (
          <p className="alert alert-danger">{props.alert}</p>
        ) : null}
        <div className="container-fluid content_container">
          {props.childrenHtml ? parse(props.childrenHtml) : createComponent()}
        </div>
      </div>
      <Footer
        categories={categories}
        images={props.images}
        user_signed_in={props.user_signed_in}
        user_is_premium={
          !!(props.current_user && props.current_user.is_premium)
        }
      />
    </>
  )
}
