import React, { MouseEvent, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

export default function ImportUsersForm(props: {}) {
  const [importText, setImportText] = useState("")

  async function importButtonClick(event: MouseEvent) {
    event.preventDefault()

    const response = await fetch("/users/import", {
      method: "POST",
      body: JSON.stringify({ importText }),
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
    })
    alert(await response.text())
  }

  const importTextExample = `email: testUser1@klimekreviews.com
firstName: Test
lastName: User1
school: Cool Cat School

email: testUser2@klimekreviews.com
firstName: Test
lastName: User2
school: Coolest Cats School`

  return (
    <Container className="booyah-box ImportUsersForm">
      <Row>
        <Col md>
          <h1 className="bold text-center">Import Users</h1>
        </Col>
      </Row>
      <Col md>
        <h2>Example:</h2>
        <pre>{importTextExample}</pre>
      </Col>
      <Row></Row>
      <Row>
        <Col>
          <textarea
            onChange={(event) => {
              setImportText(event.target.value)
            }}
            value={importText}
          ></textarea>
          <button className="btn btn-primary" onClick={importButtonClick}>
            Import
          </button>
        </Col>
      </Row>
    </Container>
  )
}
