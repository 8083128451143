import React, { Key, useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"

import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import log from "./log"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"
import { TopicWithBookmarkCount } from "./taxonomy/TopicWithBookmarkCount"

function filterOutTopicsWithoutBoookmarks(
  topics: TopicWithBookmarkCount[]
): TopicWithBookmarkCount[] {
  return topics.filter((topic) => {
    return topic.bookmark_count > 0
  })
}

function filterOutCategoriesWithoutBookmarks(
  categories: CategoryWithTopicsAndBookmarkCount[]
): CategoryWithTopicsAndBookmarkCount[] {
  return categories.filter((category) => {
    return filterOutTopicsWithoutBoookmarks(category.topics).length > 0
  })
}

export default function Bookmarks(props: {
  categories: CategoryWithTopicsAndBookmarkCount[] | null
  images: KlimekReviewsImageUrls
  user_is_premium: boolean
  onCloseClick(): void
}) {
  useEffect(function () {
    log(Bookmarks, "categories", props.categories)
  }, [])

  const [expandCategoryID, setExpandCategoryID] = useState<number | null>(null)

  function onCategoryClick(category: CategoryWithTopicsAndBookmarkCount) {
    if (expandCategoryID == category.id) {
      setExpandCategoryID(null)
    } else {
      setExpandCategoryID(category.id)
    }
  }

  function onTopicClick(topic: TopicWithBookmarkCount) {
    window.location.href = `/topic/${topic.topic_id}?show_only_bookmarked=true`
  }

  function renderCategories() {
    if (props.categories === null) {
      return "Loading..."
    }

    if (props.categories.length <= 0) {
      return "No bookmarked questions found."
    }

    return filterOutCategoriesWithoutBookmarks(props.categories).map(
      (category, index) => {
        return (
          <div key={index}>
            <div
              className="modal_link categories_text"
              onClick={() => onCategoryClick(category)}
            >
              ‣ {category.name}
            </div>
            {expandCategoryID == category.id ? (
              <ul>
                {filterOutTopicsWithoutBoookmarks(category.topics).map(
                  (topic: TopicWithBookmarkCount, topicIndex: Key) => {
                    if (!topic.topic_name.trim().length) {
                      throw new Error("wtf")
                    }

                    return (
                      <li
                        className="modal_link"
                        onClick={() => onTopicClick(topic)}
                        key={topicIndex}
                      >
                        {topic.topic_name} ( {topic.bookmark_count}
                        <img
                          className="bookmarkedQuestionsCountIcon"
                          src={props.images.bookmarkTenPixels}
                          alt="bookmarked questions"
                        />
                        )
                      </li>
                    )
                  }
                )}
              </ul>
            ) : null}
          </div>
        )
      }
    )
  }

  return (
    <>
      <Modal
        centered={true}
        dialogClassName="modal_size_increase"
        backdrop="static"
        show={true}
        onHide={() => props.onCloseClick()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="bold">Bookmarks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{renderCategories()}</div>
        </Modal.Body>
      </Modal>
    </>
  )
}
