import React, { MouseEvent, useState } from "react"

import Bookmarks from "./Bookmarks"
import CategoriesAndTopics from "./CategoriesAndTopics"
import { CurrentUserMenu } from "./CurrentUserMenu"
import FrontPageSmallBox from "./FrontPageSmallBox"
import InstructionsModal from "./InstructionsModal"
import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import { goToEventLinkUrl } from "./Navigation"
import PaywallModal from "./PaywallModal"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"

export default function Header(props: {
  categories: CategoryWithTopicsAndBookmarkCount[] | null
  images: KlimekReviewsImageUrls
  user_signed_in: boolean
  user_is_premium: boolean
  current_user: {
    first_name: string
    is_admin: boolean
    last_name: string
  } | null
}) {
  const [collapsed, setCollapsed] = useState(true)
  const [showPremiumFeaturesModal, setShowPremiumFeaturesModal] =
    useState(false)
  const [featureName, setFeatureName] = useState("")
  const [showCategoriesAndTopicsModal, setShowCategoriesAndTopicsModal] =
    useState(false)
  const [showBookmarksModal, setShowBookmarksModal] = useState(false)
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false)

  function toggleNavbar() {
    setCollapsed(!collapsed)
  }

  function onInstructionsClick(event: MouseEvent) {
    event.preventDefault()
    setShowInstructionsDialog(true)
  }

  function closeInstructionsDialog() {
    setShowInstructionsDialog(false)
  }

  function closePremiumFeaturesModal(event?: MouseEvent) {
    // It's necessary to cancel the link's click event, hide the dialog, and then go to the link's URL.
    // Without taking these extra steps, pressing "back" (while the linked page is still loading) takes
    // you back to this page, but a non-interactive version of it that you can't escape without a page
    // reload.

    if (event) {
      event.preventDefault()
    }

    setShowPremiumFeaturesModal(false)

    if (event) {
      goToEventLinkUrl(event)
    }
  }

  function closeCTModal() {
    setShowCategoriesAndTopicsModal(false)
  }

  function closeBookmarksModal() {
    setShowBookmarksModal(false)
  }

  function onCTClick(event: MouseEvent) {
    event.preventDefault()
    if (props.user_is_premium) {
      setShowCategoriesAndTopicsModal(true)
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Categories and Topics")
    }
  }

  function onBookmarkQuestionsClick(event: MouseEvent) {
    event.preventDefault()
    if (props.user_is_premium) {
      setShowBookmarksModal(true)
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Bookmarked Questions")
    }
  }

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div
          className={`container-fluid header_menu ${
            collapsed ? `header_menu_collapsed` : ``
          }`}
        >
          <div className="nav_icon_pad home_icon_head">
            <a href="/" className="nav-home-icon" aria-label="Home"></a>
          </div>
          <div className={`${classOne}`} id="navbarResponsive">
            <nav className="mr-auto">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item nav-link">
                  <a
                    href="#"
                    className="nav_border"
                    onClick={(event) => onInstructionsClick(event)}
                  >
                    <div className="link_with_icon">
                      <div className="instruction_icon"></div>
                      Instructions
                    </div>
                  </a>
                </li>
                <li className="nav-item nav-link">
                  <a
                    className="nav_border"
                    href="#"
                    onClick={(event) => onCTClick(event)}
                  >
                    <div className="link_with_icon">
                      <div className="cat_top_icon"></div>
                      Categories &amp;
                      <br />
                      Topics
                    </div>
                  </a>
                </li>
                <li className="nav-item nav-link">
                  <a
                    className="nav_border"
                    href="#"
                    onClick={(event) => onBookmarkQuestionsClick(event)}
                  >
                    <div className="link_with_icon">
                      <div className="bookmark_icon"></div>
                      Bookmarked
                      <br />
                      Questions
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              {props.user_signed_in ? (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <CurrentUserMenu current_user={props.current_user} />
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="/users/sign_in" className="nav-link">
                      Log In
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/users/sign_up" className="btn btn-primary btn-lg">
                      Create Account
                    </a>
                  </li>
                </ul>
              )}
            </nav>
          </div>
          <button
            onClick={toggleNavbar}
            className={`${classTwo}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
      <PaywallModal
        isVisible={showPremiumFeaturesModal}
        boxTitle={featureName}
        onCloseClick={() => closePremiumFeaturesModal()}
      >
        {/*
            This button must close the dialog, or else when the user presses it and comes back to this page, the dialog is still open and unclosable.
            See: https://velansolutions.atlassian.net/browse/NW-40
          */}
        <FrontPageSmallBox
          title="This is a Premium Feature!"
          description1="Want to gain access to over 2,600 review questions, bookmark functionality and all of the categories and topics?"
          description2="Purchase premium content."
          buttonLink="/payments/new"
          buttonText="Buy Now"
          buttonClickHandler={(event: MouseEvent) =>
            closePremiumFeaturesModal(event)
          }
        />
      </PaywallModal>
      <CategoriesAndTopics
        categories={props.categories}
        images={props.images}
        isVisible={showCategoriesAndTopicsModal}
        onCloseClick={() => closeCTModal()}
        user_is_premium={props.user_is_premium}
      />
      {showBookmarksModal ? (
        <Bookmarks
          categories={props.categories}
          images={props.images}
          onCloseClick={() => closeBookmarksModal()}
          user_is_premium={props.user_is_premium}
        />
      ) : null}
      {showInstructionsDialog ? (
        <InstructionsModal onCloseClick={() => closeInstructionsDialog()} />
      ) : null}
    </>
  )
}
