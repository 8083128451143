const logLifecycle = false
const pwaLoaderVersion = 4

const log = (message, ...parameters) => {
  if (logLifecycle) {
    console.log(`[PWA Loader v${pwaLoaderVersion}] ${message}`, ...parameters)
  }
}

log(`Adding page load event to load PWA service worker...`)

document.addEventListener("turbolinks:load", async () => {
  log(`Loading PWA service worker...`)

  if (!navigator.serviceWorker) {
    console.log(
      `Navigator does not support service workers. Service worker loading cancelled.`
    )
    return
  }

  const registrations = await navigator.serviceWorker.getRegistrations()

  for (const registration of registrations) {
    log("Found existing service worker, unregistering.", registration)
    registration.unregister()
  }

  navigator.serviceWorker.register(`/pwa-sw.js?v=${pwaLoaderVersion}`, {
    scope: "/",
  })
})
