import React, { useState } from "react"
import { Alert, Col, Row } from "react-bootstrap"

import { sendRequest } from "./sendRequest"

export function PromoCodeInput(props: {
  onCodeApplied(discountPercent: number, promoCode: string): void
}) {
  const [promoCode, setPromoCode] = useState("")
  const [discountPercent, setDiscountPercent] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")

  async function applyPromoCodeButtonClick() {
    console.log("applyPromoCodeButtonClick")

    const responseText = await sendRequest(
      "/api/promo_codes/apply/" + promoCode
    )
    const response = JSON.parse(responseText)

    if (response && response.success) {
      if (response.data) {
        const discountPercent = response.data.discountPercent
        if (discountPercent) {
          console.log("Discount: " + discountPercent + "%")
          setErrorMessage("")
          setDiscountPercent(discountPercent)
          props.onCodeApplied(discountPercent, promoCode)
        } else {
          throw new Error(
            "Success response has no 'data.discountPercent' property."
          )
        }
      } else {
        throw new Error("Success response has no 'data' property.")
      }
    } else {
      if (response.message) {
        console.log("Error message: " + response.message)
        setErrorMessage(response.message)
        setDiscountPercent(0)
      } else {
        throw new Error("Error response: " + responseText)
      }
    }
  }

  return (
    <>
      <Row className="no-gutters paymentFormFieldInputRow">
        <Col xs={12} lg={10}>
          <input
            className="StripeElement paymentFormFieldInput"
            type="text"
            name="promoCode"
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </Col>
        <Col xs={12} lg={2} className="applyPromoCodeButtonColumn">
          <button
            className="btn btn-primary btn-md applyPromoCodeButton"
            onClick={applyPromoCodeButtonClick}
          >
            Apply
          </button>
        </Col>
      </Row>
      {errorMessage ? (
        <Row className="no-gutters paymentFormFieldInputRow">
          <Col xs={12}>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
      {discountPercent ? (
        <Row className="no-gutters paymentFormFieldInputRow">
          <Col xs={12}>
            <Alert variant="success">Discount: {discountPercent}%</Alert>
          </Col>
        </Row>
      ) : null}
    </>
  )
}
