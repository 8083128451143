import React from "react"

export function PriceView(props: {
  discountPercent: number
  priceInCentsBeforeDiscount: number
}) {
  const finalPricePercent = 100 - props.discountPercent
  const priceInCents =
    props.priceInCentsBeforeDiscount * (finalPricePercent / 100)

  return (
    <>
      {props.priceInCentsBeforeDiscount === priceInCents ? null : (
        <>
          <h5 className="text-center">
            Price before discount: ${props.priceInCentsBeforeDiscount / 100} USD
          </h5>
          <h5 className="text-center">
            Discount: (- $
            {(props.priceInCentsBeforeDiscount - priceInCents) / 100} USD)
          </h5>
        </>
      )}
      <h5 className="bold text-center">Price: ${priceInCents / 100} USD</h5>
    </>
  )
}
