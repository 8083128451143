import React from "react"

export default function Instructions() {
  return (
    <>
      <p>This site is very simple to use:</p>

      <ol>
        <li>
          Read and answer the question, then reveal the answer to see if you got
          it right.
        </li>
        <li>Click the bookmark button if you answered incorrectly.</li>
        <li>
          After going through all 2500+ questions once, go to the bookmarked
          questions (in the upper right of the screen) and answer them.
        </li>
        <li>
          When you answer a bookmarked question correctly, “unbookmark” it.
          Repeat this step until no questions are bookmarked.
        </li>
      </ol>

      <p>
        All students who attend our in-person Klimek Reviews sessions are
        required to master this information before taking the NCLEX.
      </p>
    </>
  )
}
