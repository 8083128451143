import React from "react"

export default function QuestionDisplay(props: { questionText: string }) {
  return (
    <>
      <div className="question">
        {""}
        {props.questionText ? props.questionText : "Loading..."}
      </div>
    </>
  )
}
