import { MouseEvent } from "react"

const goToEventLinkUrl = (event: MouseEvent) => {
  const target = event.target

  if (!(target instanceof HTMLElement)) {
    throw new Error("Target is not an HTMLElement: " + target)
  }

  const linkUrl = target.getAttribute("href")

  console.log("linkUrl", linkUrl)

  if (linkUrl) {
    window.location.href = linkUrl
  }
}

export { goToEventLinkUrl }
