import React from "react"

import FrontPageSmallBox from "./FrontPageSmallBox"

export default function UserSignedInFrontPageBox(props: {
  onTryForFreeClick(event: unknown): void
}) {
  return (
    <>
      <div className="booyah-box col-10 offset-1 front_page_align">
        <h1 className="bold text-center">
          {" "}
          Thank you for signing up for the Official Klimek Reviews Blue Book
          App!
        </h1>
        <hr />
        <div className="row text-center">
          <div className="col-sm-12 col-12 col-lg-6">
            <FrontPageSmallBox
              title="Not convinced yet?"
              description1=" Continue testing out 20 more"
              description2=" sample questions on us!"
              buttonText="Keep Testing!"
              buttonClickHandler={(event: unknown) =>
                props.onTryForFreeClick(event)
              }
            />
          </div>
          <div className="col-sm-12 col-12 col-lg-6">
            <FrontPageSmallBox
              title="Ready to ace the test?"
              description1="Gain access to over 2,600 review questions!"
              description2="Purchase premium content now!"
              buttonLink="/payments/new"
              buttonText="Buy Now"
            />
          </div>
        </div>
      </div>
    </>
  )
}
