import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

import CreatePromoCodesForm from "./CreatePromoCodesForm"
import PromoCodesList from "./PromoCodesList"

export default function AdminDashboard(props: {
  user_signed_in: boolean
  user_is_admin: boolean
}) {
  const showDashboard = props.user_is_admin && props.user_signed_in

  if (showDashboard) {
    return (
      <div className="AdminDashboard">
        <Container className="booyah-box">
          <Row>
            <Col md>
              <CreatePromoCodesForm />
            </Col>
            <Col md>
              <PromoCodesList />
            </Col>
          </Row>
        </Container>
        <Container className="booyah-box">
          <Row>
            <Col md>
              <Button href="/users/import">Import Users</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    return null
  }
}
