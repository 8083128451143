import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"

import CheckoutForm from "./CheckoutForm"

export default function StripeProviderBox(props: {
  apiKey: string
  email: string
  fullName: string
  paymentAmountCents: number
}) {
  const stripePromise = loadStripe(props.apiKey)

  return (
    <div className="booyah-box stripe_provider_pad col-md-6 offset-md-3 col-12">
      <h1 className="text-center">Purchase Premium Content </h1>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          email={props.email}
          fullName={props.fullName}
          paymentAmountCents={props.paymentAmountCents}
        />
      </Elements>
    </div>
  )
}
