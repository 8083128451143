import React from "react"
import Modal from "react-bootstrap/Modal"

import Instructions from "./Instructions"

export default function InstructionsModal(props: { onCloseClick(): void }) {
  return (
    <>
      <Modal
        centered={true}
        dialogClassName="modal_size_increase"
        backdrop="static"
        show={true}
        onHide={() => props.onCloseClick()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="italic">Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Instructions />
        </Modal.Body>
      </Modal>
    </>
  )
}
