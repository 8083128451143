import React, { MouseEvent, useState } from "react"

import FrontPageSmallBox from "./FrontPageSmallBox"
import InstructionsModal from "./InstructionsModal"

export default function FrontIntroBox(props: {
  MarkKlimekImageUrl: string
  onTryForFreeClick(event: unknown): void
}) {
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false)

  function onInstructionsClick(event: MouseEvent) {
    event.preventDefault()
    setShowInstructionsDialog(true)
  }

  function closeInstructionsDialog() {
    setShowInstructionsDialog(false)
  }

  return (
    <>
      <div className="text-center">
        <br />
        <div className="booyah-box col-10 offset-1 front_page_align">
          <h1 className="bold">
            {" "}
            Welcome to the Official Klimek Reviews Blue Book: Gold Edition App!
          </h1>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-12 col-lg-3">
              <br />
              <img
                src={props.MarkKlimekImageUrl}
                alt="Picture of Mark Klimek"
              />
            </div>
            <div className="col-sm-12 col-12 col-lg-8 left-justified">
              <h4 className="bold">A note from Mark Klimek:</h4>
              <h5 className="italic">
                If I was your personal tutor for the National Certification
                Licensure Exam (NCLEX), I would tell you everything you need to
                know before you take the exam, and it would be exactly what we
                cover in our Live Review and Blue Book. I would ask you hundreds
                of questions, and they would be the exact questions in our Blue
                Book. I would give you the best answer to every question, and
                those answers are in our Blue Book. Historically, our Blue Book
                has only been available to students that attend our live,
                in-person review, but now, anyone can access it thanks to this
                website.
              </h5>
              <h5 className="italic left-justified"> Signed, </h5>
              <h5 className="italic left-justified"> Mark Klimek </h5>
            </div>
            <div className="col-sm-12 col-12 col-lg-1"></div>
          </div>
          <div className="col-sm-12 col-12 col-lg-1"></div>
          <hr />
          <a
            href="#"
            className=" try_free btn btn-primary btn-lg"
            onClick={(event) => props.onTryForFreeClick(event)}
          >
            {" "}
            Try it out for free!{" "}
          </a>
        </div>
      </div>
      <div className="row feature_columns text-center bold">
        <div className="col-lg-4 col-12 feature_column">
          <div className="booyah-box feature-box">
            <FrontPageSmallBox
              title="How it works!"
              description1="The website is very simple to use"
              description2="Click here to learn more."
              buttonLink="#"
              buttonText="Instructions"
              buttonClickHandler={(event: MouseEvent) =>
                onInstructionsClick(event)
              }
            />
          </div>
        </div>
        <div className="col-lg-4 col-12 feature_column">
          <div className="booyah-box feature-box">
            <FrontPageSmallBox
              title="Not convinced yet?"
              description1="Try 20 more sample questions!"
              description2="Register for a free account."
              buttonLink="/users/sign_up"
              buttonText="Create Account"
            />
          </div>
        </div>
        <div className="col-lg-4 col-12 feature_column">
          <div className="booyah-box feature-box">
            <FrontPageSmallBox
              title="Ready to ace the test?"
              description1="Gain access to over 2,600 review questions!"
              description2="Purchase premium content."
              buttonLink="/payments/new"
              buttonText="Buy Now"
            />
          </div>
        </div>
      </div>
      {showInstructionsDialog ? (
        <InstructionsModal onCloseClick={() => closeInstructionsDialog()} />
      ) : null}
    </>
  )
}
