import React, { MouseEvent, useState } from "react"

import Bookmarks from "./Bookmarks"
import CategoriesAndTopics from "./CategoriesAndTopics"
import FrontPageSmallBox from "./FrontPageSmallBox"
import InstructionsModal from "./InstructionsModal"
import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import { goToEventLinkUrl } from "./Navigation"
import PaywallModal from "./PaywallModal"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"

export default function Footer(props: {
  categories: CategoryWithTopicsAndBookmarkCount[] | null
  images: KlimekReviewsImageUrls
  user_signed_in: boolean
  user_is_premium: boolean
}) {
  const [featureName, setFeatureName] = useState("")
  const [showBookmarksModal, setShowBookmarksModal] = useState(false)
  const [showCategoriesAndTopicsModal, setShowCategoriesAndTopicsModal] =
    useState(false)
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false)
  const [showPremiumFeaturesModal, setShowPremiumFeaturesModal] =
    useState(false)

  function onCategoriesAndTopicsClick(event: MouseEvent) {
    event.preventDefault()

    if (props.user_is_premium) {
      setShowCategoriesAndTopicsModal(true)
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Categories and Topics")
    }
  }

  function closeCategoriesAndTopicsModal() {
    setShowCategoriesAndTopicsModal(false)
  }

  function onBookmarkedQuestionsClick(event: MouseEvent) {
    event.preventDefault()

    if (props.user_is_premium) {
      setShowBookmarksModal(true)
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Bookmarked Questions")
    }
  }

  function closeBookmarksModal() {
    setShowBookmarksModal(false)
  }

  function onInstructionsClick(event: MouseEvent) {
    event.preventDefault()
    setShowInstructionsDialog(true)
  }

  function closePremiumFeaturesModal(event?: MouseEvent) {
    // It's necessary to cancel the link's click event, hide the dialog, and then go to the link's URL.
    // Without taking these extra steps, pressing "back" (while the linked page is still loading) takes
    // you back to this page, but a non-interactive version of it that you can't escape without a page
    // reload.

    if (event) {
      event.preventDefault()
    }

    setShowPremiumFeaturesModal(false)

    if (event) {
      goToEventLinkUrl(event)
    }
  }

  function closeInstructionsDialog() {
    setShowInstructionsDialog(false)
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-3">
              <div className="title_footer bold underline">Navigation</div>
              <a href="/">Home</a>
              <br />
              <a
                href="#"
                onClick={(event) => onCategoriesAndTopicsClick(event)}
              >
                Categories &amp; Topics
              </a>
              <br />
              <a
                href="#"
                onClick={(event) => onBookmarkedQuestionsClick(event)}
              >
                Bookmarked Questions
              </a>
              <br />
              <a href="#" onClick={(event) => onInstructionsClick(event)}>
                Instructions
              </a>
            </div>
            <div className="col-6 col-sm-3">
              <div className="title_footer bold underline">About</div>
              <a
                href="https://klimekreviews.com/"
                target="_blank"
                rel="noopener"
              >
                Klimek Reviews
              </a>
              <br />
              <a
                href="https://klimekreviews.com/faq/"
                target="_blank"
                rel="noopener"
              >
                Klimek Reviews FAQ
              </a>
              <br />
              <a
                href="https://klimekreviews.com/terms-conditions/"
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions
              </a>
              <br />
              <a href="mailto:support@klimekreviews.com">Contact Us</a>
            </div>
            <div className="col-6 col-sm-3 footer_icon">
              <a href="/" className="nav-home-icon" aria-label="Home"></a>
            </div>
            <div className="col-6 col-sm-3">
              <br />
              <b>Klimek Reviews</b>
              <br />
              Copyright 2019
              <br />
              All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
      <PaywallModal
        isVisible={showPremiumFeaturesModal}
        boxTitle={featureName}
        onCloseClick={() => closePremiumFeaturesModal()}
      >
        {/*
            This button must close the dialog, or else when the user presses it and comes back to this page, the dialog is still open and unclosable.
            See: https://velansolutions.atlassian.net/browse/NW-40
          */}
        <FrontPageSmallBox
          title="This is a Premium Feature!"
          description1="Want to gain access to over 2,600 review questions, bookmark functionality and all of the categories and topics?"
          description2="Purchase premium content."
          buttonLink="/payments/new"
          buttonText="Buy Now"
          buttonClickHandler={(event: MouseEvent) =>
            closePremiumFeaturesModal(event)
          }
        />
      </PaywallModal>
      <CategoriesAndTopics
        categories={props.categories}
        images={props.images}
        isVisible={showCategoriesAndTopicsModal}
        onCloseClick={() => closeCategoriesAndTopicsModal()}
        user_is_premium={props.user_is_premium}
      />
      {showBookmarksModal ? (
        <Bookmarks
          categories={props.categories}
          images={props.images}
          onCloseClick={() => closeBookmarksModal()}
          user_is_premium={props.user_is_premium}
        />
      ) : null}
      {showInstructionsDialog ? (
        <InstructionsModal onCloseClick={() => closeInstructionsDialog()} />
      ) : null}
    </React.Fragment>
  )
}
