import { sendRequest } from "../sendRequest"
import { CategoryWithTopicsAndBookmarkCount } from "../taxonomy/CategoryWithTopicsAndBookmarkCount"
import { TaxonomyDataParser } from "../taxonomy/TaxonomyDataParser"

export async function answerQuestion(
  questionId: number,
  onAnsweredQuestionsCountByTopicReceived: (
    answeredQuestionsCountByTopic: Record<number, number>
  ) => void
): Promise<void> {
  console.log("answerQuestion " + questionId)

  const responseText = await sendRequest(
    "/api/answering_questions/answer?questionId=" + questionId
  )
  const response = JSON.parse(responseText)

  if (response && response.success) {
    if (response.data) {
      const answeredQuestionsCountByTopic =
        TaxonomyDataParser.parseAnsweredQuestionsCountByTopic(responseText)
      onAnsweredQuestionsCountByTopicReceived(answeredQuestionsCountByTopic)
    } else {
      throw new Error("Success response has no 'data' property.")
    }
  } else {
    if (response.message) {
      console.log("Error message: " + response.message)
    } else {
      throw new Error("Error response: " + responseText)
    }
  }
}
