import React, { MouseEvent, useEffect, useState } from "react"
import Alert from "react-bootstrap/Alert"

import AnswerDisplay from "./AnswerDisplay"
import ArrowKeysReact from "./ArrowKeysReact"
import CategoriesAndTopics from "./CategoriesAndTopics"
import FrontPageSmallBox from "./FrontPageSmallBox"
import InstructionsModal from "./InstructionsModal"
import log from "./log"
import { goToEventLinkUrl } from "./Navigation"
import PaywallModal from "./PaywallModal"
import QuestionDisplay from "./QuestionDisplay"
import { HideOrRevealAnswerButton } from "./answering-questions/HideOrRevealAnswerButton"
import { Question } from "./questions/Question"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"
import { TaxonomyDataParser } from "./taxonomy/TaxonomyDataParser"
import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"

type FlashcardsProps = {
  categories: CategoryWithTopicsAndBookmarkCount[]
  images: KlimekReviewsImageUrls
  show_only_bookmarked: boolean
  topic_id: number
  user_is_premium: boolean
  user_signed_in: boolean
  onAnsweredQuestionsCountByTopicReceived(
    answeredQuestionsCountByTopic: Record<number, number>
  ): void
  onCategoriesReceived(categories: CategoryWithTopicsAndBookmarkCount[]): void
}

export default function Flashcards(props: FlashcardsProps) {
  const [alertMessage, setAlertMessage] = useState<null | string>(null)
  const [answerDisplayed, setAnswerDisplayed] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null)
  const [featureName, setFeatureName] = useState("")
  const [questions, setQuestions] = useState<null | {
    [key: string | number | symbol]: Question
  }>(null)
  const [questionsList, setQuestionsList] = useState<null | Question[]>(null)
  const [questionsPagingInfo, setQuestionsPagingInfo] = useState<null | {
    next?: unknown
    prev?: unknown
  }>(null)
  const [showCTModal, setShowCTModal] = useState(false)
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false)
  const [showMoreQuestionsModal, setShowMoreQuestionsModal] = useState(false)
  const [showPremiumFeaturesModal, setShowPremiumFeaturesModal] =
    useState(false)

  useEffect(function () {
    ;(async function () {
      setUpArrowKeys()

      if (flashCardRow) {
        flashCardRow.focus()
      }

      await fetchQuestions()
    })()
  }, [])

  async function onRemoveBookmarkClick() {
    if (!currentQuestion) {
      log(
        Flashcards,
        `"onRemoveBookmarkClick" cannot be called when "currentQuestion" is null.`
      )
      return
    }

    if (props.user_is_premium) {
      setCurrentQuestion({
        ...currentQuestion,
        isBookmarked: false,
      })

      const response = await fetch("/api/bookmarks", {
        method: "DELETE",
        body: JSON.stringify({ question_id: currentQuestion.id }),
        headers: { "Content-Type": "application/json" },
      })
      if (response.ok) {
        if (props.show_only_bookmarked) {
          removeCurrentQuestionFromQuestions()
        }
        removeCurrentQuestionFromBookmarks()

        const categories =
          TaxonomyDataParser.parseCategoriesWithTopicsAndBookmarkCounts(
            await response.text()
          )
        props.onCategoriesReceived(categories)
      } else {
        console.error(Flashcards, "Unable to un-bookmark question.")
      }
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Bookmarked Questions")
    }
  }

  async function onBookmarkableClick() {
    if (!currentQuestion) {
      log(
        Flashcards,
        `"onBookmarkableClick" cannot be called when "currentQuestion" is null.`
      )
      return
    }

    if (props.user_is_premium) {
      setCurrentQuestion({
        ...currentQuestion,
        isBookmarked: true,
      })

      const response = await fetch("/api/bookmarks", {
        method: "POST",
        body: JSON.stringify({ question_id: currentQuestion.id }),
        headers: { "Content-Type": "application/json" },
      })
      if (response.ok) {
        const categories =
          TaxonomyDataParser.parseCategoriesWithTopicsAndBookmarkCounts(
            await response.text()
          )
        props.onCategoriesReceived(categories)
      } else {
        console.error(Flashcards, "Unable to bookmark question.")
      }
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Bookmarked Questions")
    }
  }

  function removeCurrentQuestionFromQuestions() {
    if (!currentQuestion) {
      log(
        Flashcards,
        `"removeCurrentQuestionFromQuestions" cannot be called when "currentQuestion" is null.`
      )
      return
    }
    if (!questionsList) {
      log(
        Flashcards,
        `"removeCurrentQuestionFromQuestions" cannot be called when "questionsList" is null.`
      )
      return
    }

    setQuestionsList(
      questionsList.filter((question) => {
        return question.id !== currentQuestion.id
      })
    )

    setQuestions(getQuestionsByPosition(questionsList))
  }

  function removeCurrentQuestionFromBookmarks() {
    if (!currentQuestion) {
      log(
        Flashcards,
        `"removeCurrentQuestionFromBookmarks" cannot be called when "currentQuestion" is null.`
      )
      return
    }
  }

  async function fetchQuestions(
    page: unknown | null = null,
    isPrevious: unknown | null = null
  ) {
    log(
      Flashcards,
      `fetching questions page ${page} topic_id ${props.topic_id}`
    )

    let url = `/api/questions?show_only_bookmarked=${props.show_only_bookmarked}`

    if (page === null && props.topic_id !== null) {
      url += `&topic_id=${props.topic_id}`
    } else if (page === null) {
      url += ``
    } else {
      url += `&page=${page}`
    }

    const response = await fetch(url)

    if (response.ok) {
      const responseText = await response.text()
      const questionsPage = JSON.parse(responseText)
      const questionsPagingInfo = questionsPage.paging_info
      const questionsList = questionsPage.data
      const questionPosition: string | number | symbol =
        questionsPage.question_position
      const questions = getQuestionsByPosition(questionsList)

      log(Flashcards, "questionsList", questionsList)
      log(Flashcards, "questionPosition", questionPosition)
      log(Flashcards, "questions", questions)

      let currentQuestion = null
      if (isPrevious === true) {
        currentQuestion = questionsList[questionsList.length - 1]
      } else if (isPrevious === false) {
        currentQuestion = questionsList[0]
      } else if (questionPosition) {
        currentQuestion = questions[questionPosition]
      } else {
        currentQuestion = questionsList[0]
      }
      log(Flashcards, "currentQuestion", currentQuestion)

      setQuestions(questions)
      setQuestionsList(questionsList)
      setQuestionsPagingInfo(questionsPagingInfo)
      setCurrentQuestion(currentQuestion)
      setAnswerDisplayed(false)
    } else {
      log(Flashcards, "FETCHING QUESTIONS FAILED")
    }
  }

  function getQuestionsByPosition(questionsList: Question[]): {
    [key: string | number | symbol]: Question
  } {
    const initialState: { [key: string | number | symbol]: Question } = {}

    const result = questionsList.reduce((accumulator, question) => {
      return { ...accumulator, [question.position]: question }
    }, initialState)

    return result
  }

  function onInstructionsClick(event: MouseEvent) {
    event.preventDefault()
    setShowInstructionsDialog(true)
  }

  function closeInstructionsDialog() {
    setShowInstructionsDialog(false)
  }

  function closeCTModal() {
    setShowCTModal(false)
  }

  function onCategoriesAndTopicsButtonClick(event: MouseEvent) {
    log(Flashcards, "onCategoriesAndTopicsButtonClick")
    event.preventDefault()
    showCategoriesAndTopicsDialog()
  }

  function showCategoriesAndTopicsDialog() {
    if (props.user_is_premium) {
      setShowCTModal(true)
    } else {
      setShowPremiumFeaturesModal(true)
      setFeatureName("Categories and Topics")
    }
  }

  function onRevealAnswerClick() {
    setAnswerDisplayed(!answerDisplayed)
    currentQuestion.hasBeenAnswered = true
  }

  async function onPreviousClick() {
    log(Flashcards, "onPreviousClick")

    if (props.show_only_bookmarked) {
      onPreviousClickBookmarkedNavigation()
      return
    }

    const newQuestionPosition = currentQuestion.position - 1

    if (questions.hasOwnProperty(newQuestionPosition)) {
      const newCurrentQuestion = questions[newQuestionPosition]
      log(Flashcards, "newCurrentQuestion", newCurrentQuestion)

      setCurrentQuestion(newCurrentQuestion)
    } else {
      if (questionsPagingInfo.prev === null) {
        alert("This is the first question. There is no previous question.")
        return
      } else {
        const isPrevious = true
        await fetchQuestions(questionsPagingInfo.prev, isPrevious)
      }
    }

    setAnswerDisplayed(false)
    setAlertMessage(null)
  }

  async function onPreviousClickBookmarkedNavigation() {
    log(Flashcards, "onPreviousClickBookmarkedNavigation2", questionsList)

    const newQuestionReversedIndex = [...questionsList]
      .reverse()
      .findIndex((question) => {
        log(Flashcards, "question.position", question.position)
        return question.position < currentQuestion.position
      })

    if (newQuestionReversedIndex < 0) {
      alert(
        "This is the first bookmarked question in this topic. Please select another topic."
      )
      return
    }

    if (
      newQuestionReversedIndex > questionsList.length ||
      newQuestionReversedIndex === questionsList.length
    ) {
      alert(
        `Unable to find a question with a position greater than ${currentQuestion.position}.`
      )
      return
    }

    const newQuestion = [...questionsList].reverse()[newQuestionReversedIndex]
    log(Flashcards, "newQuestion", newQuestion)

    setCurrentQuestion(newQuestion)
    setAnswerDisplayed(false)
    setAlertMessage(null)
  }

  async function onNextClick() {
    log(Flashcards, "onNextClick")

    if (props.show_only_bookmarked) {
      onNextClickBookmarkedNavigation()
      return
    }

    const newQuestionPosition = currentQuestion.position + 1

    if (questions.hasOwnProperty(newQuestionPosition)) {
      const newCurrentQuestion = questions[newQuestionPosition]
      log(Flashcards, "newCurrentQuestion", newCurrentQuestion)

      setCurrentQuestion(newCurrentQuestion)
    } else {
      if (questionsPagingInfo.next === null) {
        if (props.user_is_premium) {
          alert("This is the last question. There is no next question.")
        } else {
          setShowMoreQuestionsModal(true)
        }
        return
      } else {
        const isPrevious = false
        await fetchQuestions(questionsPagingInfo.next, isPrevious)
      }
    }

    setAnswerDisplayed(false)
    setAlertMessage(null)
  }

  async function onNextClickBookmarkedNavigation() {
    log(Flashcards, "onNextClickBookmarkedNavigation", questionsList)

    const newQuestionIndex = questionsList.findIndex(
      (question) => question.position > currentQuestion.position
    )

    if (newQuestionIndex < 0) {
      alert(
        `Unable to find a question with a position greater than ${currentQuestion.position}.`
      )
      return
    }

    if (
      newQuestionIndex > questionsList.length ||
      newQuestionIndex === questionsList.length
    ) {
      alert(
        "This is the last bookmarked question in this topic. Please select another topic."
      )
      return
    }

    const newQuestion = questionsList[newQuestionIndex]
    log(Flashcards, "newQuestion", newQuestion)

    setCurrentQuestion(newQuestion)
    setAnswerDisplayed(false)
    setAlertMessage(null)
  }

  function closePremiumFeaturesModal(event?: MouseEvent): void {
    // It's necessary to cancel the link's click event, hide the dialog, and then go to the link's URL.
    // Without taking these extra steps, pressing "back" (while the linked page is still loading) takes
    // you back to this page, but a non-interactive version of it that you can't escape without a page
    // reload.

    if (event) {
      event.preventDefault()
    }

    setShowPremiumFeaturesModal(false)

    if (event) {
      goToEventLinkUrl(event)
    }
  }

  function closeMoreQuestionsModal(event?: MouseEvent): void {
    // It's necessary to cancel the link's click event, hide the dialog, and then go to the link's URL.
    // Without taking these extra steps, pressing "back" (while the linked page is still loading) takes
    // you back to this page, but a non-interactive version of it that you can't escape without a page
    // reload.

    if (event) {
      event.preventDefault()
    }

    setShowMoreQuestionsModal(false)

    if (event) {
      goToEventLinkUrl(event)
    }
  }

  function alert(alertMessage: string): void {
    setAlertMessage(alertMessage)
  }

  function setUpArrowKeys() {
    log(Flashcards, "setUpArrowKeys")

    ArrowKeysReact.config({
      left: () => {
        onPreviousClick()
      },
      right: () => {
        onNextClick()
      },
      up: () => {
        onRevealAnswerClick()
      },
      down: () => {
        onRevealAnswerClick()
      },
      b: () => {
        if (currentQuestion.isBookmarked) {
          onRemoveBookmarkClick()
        } else {
          onBookmarkableClick()
        }
      },
    })
  }

  let flashCardRow: HTMLDivElement | null = null

  return (
    <>
      <div
        className="row flashcard_row"
        {...ArrowKeysReact.events}
        tabIndex={1}
        ref={(flashcardRow) => {
          flashCardRow = flashcardRow
        }}
      >
        <div className="flash_card_booyah col-lg-8 col-12 col-md-10">
          <div className="row flash_card_header">
            <div className="col-sm-5 col-12 cat_horizon">
              <div>
                <div className="category">
                  <span className="bold">Category:</span>{" "}
                  {currentQuestion
                    ? currentQuestion.category.name
                    : "Loading..."}
                </div>
                <div className="topic">
                  <span className="bold">Topic:</span> {""}
                  {currentQuestion ? currentQuestion.topic.name : "Loading..."}
                </div>
              </div>
            </div>
            <div className="flashcard_icons col-sm-7 col-12">
              <a href="#" onClick={(event) => onInstructionsClick(event)}>
                <div className="instruction_icon_flash marg_icon_right"></div>
              </a>
              <a
                href="#"
                onClick={(event) => onCategoriesAndTopicsButtonClick(event)}
              >
                <div className="cat_top_flash marg_icon_right"></div>
              </a>
              <div className="bookmark">
                {currentQuestion && currentQuestion.isBookmarked ? (
                  <div
                    className="bookmarkP"
                    onClick={() => onRemoveBookmarkClick()}
                  ></div>
                ) : (
                  <div
                    className="bookmark_NP"
                    onClick={() => onBookmarkableClick()}
                  ></div>
                )}
              </div>
            </div>
          </div>
          <hr className="hrWhite_top" />
          <div className="q-and-a-display">
            <QuestionDisplay
              questionText={currentQuestion ? currentQuestion.question : null}
            />
            <AnswerDisplay
              answerDisplayed={answerDisplayed}
              answers={currentQuestion ? currentQuestion.answers : null}
            />
            {currentQuestion && currentQuestion.hasBeenAnswered ? (
              <div className="hasBeenAnswered">
                <img src={props.images.checkmark} />
                <span className="messageText">
                  You have answered this question.
                </span>
              </div>
            ) : null}
          </div>
          <hr className="hrWhite_bottom" />
          <div className="row">
            {alertMessage ? (
              <Alert variant="warning">{alertMessage}</Alert>
            ) : null}
          </div>
          <div className="row reveal_row">
            {currentQuestion ? (
              <HideOrRevealAnswerButton
                isAnswerDisplayed={answerDisplayed}
                questionId={currentQuestion.id}
                onAnsweredQuestionsCountByTopicReceived={
                  props.onAnsweredQuestionsCountByTopicReceived
                }
                onRevealAnswerClick={() => {
                  onRevealAnswerClick()
                }}
                size="smallOrSmaller"
              />
            ) : null}
          </div>
          <div className="row text-center arrow_row">
            <div className="col-6 col-sm-3 arrow_container ">
              <div
                className="previous_arrow"
                onClick={() => onPreviousClick()}
              ></div>
            </div>
            {currentQuestion ? (
              <HideOrRevealAnswerButton
                isAnswerDisplayed={answerDisplayed}
                questionId={currentQuestion.id}
                onAnsweredQuestionsCountByTopicReceived={
                  props.onAnsweredQuestionsCountByTopicReceived
                }
                onRevealAnswerClick={() => {
                  onRevealAnswerClick()
                }}
                size="mediumOrLarger"
              />
            ) : null}
            <div className="col-6 col-sm-3 arrow_container ">
              <div className="next_arrow" onClick={() => onNextClick()}></div>
            </div>
          </div>
        </div>
      </div>
      <PaywallModal
        isVisible={showMoreQuestionsModal}
        boxTitle="Need more questions?"
        onCloseClick={() => closeMoreQuestionsModal()}
      >
        {/*
            These buttons must close the dialog, or else when the user presses them and comes back to this page, the dialog is still open and unclosable.
            See: https://velansolutions.atlassian.net/browse/NW-40
          */}
        {props.user_signed_in ? (
          <FrontPageSmallBox
            title="Get your premium content here!"
            description1="Gain access to over 2,600 review questions, bookmark functionality and all of the categories and topics."
            description2="Purchase premium content."
            buttonLink="/payments/new"
            buttonText="Buy Now"
            buttonClickHandler={(event: MouseEvent) =>
              closeMoreQuestionsModal(event)
            }
          />
        ) : (
          <FrontPageSmallBox
            title="Not convinced yet?"
            description1="Try 20 more sample questions on the house by registering for a free account!"
            description2=""
            buttonLink="/users/sign_up"
            buttonText="Create Account"
            buttonClickHandler={(event: MouseEvent) =>
              closeMoreQuestionsModal(event)
            }
          />
        )}
      </PaywallModal>

      <PaywallModal
        isVisible={showPremiumFeaturesModal}
        boxTitle={featureName}
        onCloseClick={() => closePremiumFeaturesModal()}
      >
        {/*
            This button must close the dialog, or else when the user presses it and comes back to this page, the dialog is still open and unclosable.
            See: https://velansolutions.atlassian.net/browse/NW-40
          */}
        <FrontPageSmallBox
          title="This is a Premium Feature!"
          description1="Want to gain access to over 2,600 review questions, bookmark functionality and all of the categories and topics?"
          description2="Purchase premium content."
          buttonLink="/payments/new"
          buttonText="Buy Now"
          buttonClickHandler={(event: MouseEvent) =>
            closePremiumFeaturesModal(event)
          }
        />
      </PaywallModal>

      <CategoriesAndTopics
        categories={props.categories}
        images={props.images}
        isVisible={showCTModal}
        onCloseClick={() => closeCTModal()}
        user_is_premium={props.user_is_premium}
      />
      {showInstructionsDialog ? (
        <InstructionsModal onCloseClick={() => closeInstructionsDialog()} />
      ) : null}
    </>
  )
}
