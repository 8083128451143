import React, { useState } from "react"

import Flashcards from "./Flashcards"
import FrontIntroBox from "./FrontIntroBox"
import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import UserSignedInFrontPageBox from "./UserSignedInFrontPageBox"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"

export default function FrontPageBox(props: {
  categories: CategoryWithTopicsAndBookmarkCount[]
  images: KlimekReviewsImageUrls
  MarkKlimekImageUrl: string
  LinkToSampleQuestions: string
  show_only_bookmarked: boolean
  user_signed_in: boolean
  user_is_premium: boolean
  topic_id: number
  onAnsweredQuestionsCountByTopicReceived(
    answeredQuestionsCountByTopic: Record<number, number>
  ): void
  onCategoriesReceived(categories: CategoryWithTopicsAndBookmarkCount[]): void
}) {
  const [visibleFlashCard, setVisibleFlashCard] = useState(
    props.user_is_premium
  )

  function onTryForFreeClickHandler(event: Event) {
    event.preventDefault()
    setVisibleFlashCard(true)
  }

  function showMainContent() {
    if (visibleFlashCard) {
      return (
        <Flashcards
          categories={props.categories}
          images={props.images}
          onAnsweredQuestionsCountByTopicReceived={
            props.onAnsweredQuestionsCountByTopicReceived
          }
          onCategoriesReceived={props.onCategoriesReceived}
          topic_id={props.topic_id}
          show_only_bookmarked={props.show_only_bookmarked}
          user_signed_in={props.user_signed_in}
          user_is_premium={props.user_is_premium}
        />
      )
    }

    if (props.user_signed_in) {
      return (
        <UserSignedInFrontPageBox
          onTryForFreeClick={(event: Event) => onTryForFreeClickHandler(event)}
        />
      )
    }

    return (
      <FrontIntroBox
        MarkKlimekImageUrl={props.MarkKlimekImageUrl}
        onTryForFreeClick={(event: Event) => onTryForFreeClickHandler(event)}
      />
    )
  }

  return showMainContent()
}
