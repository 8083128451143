const enableLogging = false

export default (
  instance: { constructor: { name: string } } | Function,
  message: string,
  ...parameters: unknown[]
) => {
  if (enableLogging) {
    const callerName =
      typeof instance === "function" ? instance.name : instance.constructor.name

    console.log(`[${callerName}] ${message}`, ...parameters)
  }
}
