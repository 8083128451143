import React from "react"
import Modal from "react-bootstrap/Modal"

export default function PaywallModal(props: {
  children: unknown
  isVisible: boolean
  onCloseClick(): void
  boxTitle: string
}) {
  return (
    <>
      <Modal
        centered={true}
        backdrop="static"
        show={props.isVisible}
        onHide={() => props.onCloseClick()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="italic">{props.boxTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="feature_columns text-center bold">
            {props.children}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
