import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { ListGroup, Badge, Container, Col, Row } from "react-bootstrap"

export default function PromoCodesList(props: unknown) {
  const [promoCodes, setPromoCodes] = useState([])

  useEffect(function () {
    ;(async function () {
      const request = new Request("/api/promo_codes/get_all", { method: "GET" })
      const response = await fetch(request)
      const jsonResponse = await response.json()
      setPromoCodes(jsonResponse.data)
    })()
  }, [])

  function listPromoCodes() {
    if (!promoCodes.length) {
      return <>No Active Promo Codes</>
    }
    return promoCodes.map((promoCode) => {
      return (
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
          key={promoCode.id}
        >
          <div className="ms-2 me-auto w-100">
            <div className="fw-bold">
              {promoCode.code} - {promoCode.discount_percent}%
            </div>
            Start date:{" "}
            {DateTime.fromSeconds(promoCode.start_timestamp).toLocaleString()}{" "}
            <br></br>
            End date:{" "}
            {DateTime.fromSeconds(promoCode.start_timestamp)
              .plus({ days: promoCode.how_many_days })
              .toLocaleString()}{" "}
          </div>
          <Container>
            <Row>
              <Col>
                <>Limit: </>
                <Badge bg="primary" pill>
                  {promoCode.usage_limit ? promoCode.usage_limit : "Unlimited"}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col>
                <>Count: </>
                <Badge bg="success" pill>
                  {promoCode.usageCount ? promoCode.usageCount : 0}
                </Badge>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
      )
    })
  }

  return (
    <>
      {promoCodes.length ? (
        <ListGroup as="ol" numbered style={{ padding: "2em 0" }}>
          {listPromoCodes()}
        </ListGroup>
      ) : (
        <>No active promo codes</>
      )}
    </>
  )
}
