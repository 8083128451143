import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import $ from "jquery"
import React, { ChangeEvent, ReactElement, useState } from "react"
import { Alert } from "react-bootstrap"

import ProgressDialog from "./ProgressDialog"
import { PromoCodeInput } from "./PromoCodeInput"
import { PriceView } from "./purchases/PriceView"

type CheckoutFormProps = {
  email: string
  fullName: string
  paymentAmountCents: number
}

const statementsToAcknowledge = [
  "I understand the Blue Book web app doesn't include NCLEX-style practice questions.",
  "I understand the Blue Book web app doesn't include a paper copy of the Blue Book.",
  "I understand the Blue Book web app doesn't include a PDF of the Blue Book questions and answers.",
]
let statementsAccepted = 0

export default function CheckoutForm(props: CheckoutFormProps) {
  const [alertMessage, setAlertMessage] = useState<ReactElement | null>(null)
  const [fullName, setFullName] = useState(props.fullName)
  const [isSuccess, setIsSuccess] = useState(false)
  const [promoCode, setPromoCode] = useState("")
  const [showProgressDialog, setShowProgressDialog] = useState(false)
  const [discountPercent, setDiscountPercent] = useState(0)

  const stripe = useStripe()
  const elements = useElements()

  function alert(alertMessage: ReactElement) {
    setAlertMessage(alertMessage)
  }

  async function processPayment() {
    if (stripe === null) {
      alert(
        <>
          Stripe is not ready to process the payment. Please try again. If this
          issue persists, contact support at: support@klimekreviews.com
        </>
      )
      return
    }

    if (elements === null) {
      alert(
        <>
          Stripe Elements is not ready to process the payment. Please try again.
          If this issue persists, contact support at: support@klimekreviews.com
        </>
      )
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (cardElement === null) {
      alert(
        <>
          Unable to get card element from Stripe Elements. Please try again. If
          this issue persists, contact support at: support@klimekreviews.com
        </>
      )
      return
    }

    const { token } = await stripe.createToken(cardElement)

    if (token === undefined) {
      alert(
        <>
          Unable to get token from Stripe. Please try again. If this issue
          persists, contact support at: support@klimekreviews.com
        </>
      )
      return
    }

    const csrfToken = $('meta[name="csrf-token"]').attr("content")

    if (csrfToken === undefined) {
      alert(
        <>
          Unable to find CSRF token in this page. Please try again. If this
          issue persists, contact support at: support@klimekreviews.com
        </>
      )
      return
    }

    const response = await fetch("/api/payments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        stripeToken: token.id,
        full_name: fullName,
        promoCode: promoCode,
      }),
    })

    setShowProgressDialog(false)

    if (response.ok) {
      setIsSuccess(true)
      setAlertMessage(null)
    } else if (response.status == 406) {
      // :not_acceptable
      const json = await response.json()

      if (
        json &&
        json.errors &&
        json.errors.full_name &&
        json.errors.full_name.includes("can't be blank")
      ) {
        alert(<>Please enter your full name.</>)
      } else {
        alert(
          <>
            Payment not processed! Please verify your card information and try
            again.
          </>
        )
      }
    } else {
      alert(
        <>Server error. Please contact support at: support@klimekreviews.com</>
      )
    }
  }

  function updateStatement(target: EventTarget) {
    if (target instanceof HTMLInputElement) {
      const accepted = target.checked
      if (accepted) {
        statementsAccepted++
      } else {
        statementsAccepted--
      }
    }
  }

  function statementsToAcknowledgeAreSelected(): boolean {
    return statementsToAcknowledge.length == statementsAccepted
  }

  async function submit() {
    const canContinue = statementsToAcknowledgeAreSelected()
    if (!canContinue) {
      alert(
        <>
          You must accept <b>all</b> conditions above, before you can place the
          order.
        </>
      )
      return
    }
    setShowProgressDialog(true)
    try {
      await processPayment()
    } catch (error) {
      setShowProgressDialog(false)
      console.log(error)
      alert(
        <>
          Uh oh! Something went wrong. Contact support at:
          support@klimekreviews.com
        </>
      )
    }
  }

  function onPromoCodeApplied(discountPercent: number, promoCode: string) {
    console.log("onPromoCodeApplied")

    if (discountPercent < 0 || discountPercent > 100) {
      throw new Error(
        "Discount percent must be between 0 and 100. Value found: " +
          discountPercent
      )
    }

    setDiscountPercent(discountPercent)
    setPromoCode(promoCode)
  }

  function onFullNameChange(e: ChangeEvent<HTMLInputElement>) {
    setFullName(e.target.value)
  }

  return (
    <>
      <div className="checkout">
        {isSuccess ? null : (
          <>
            <h4 className="italic text-center">
              Gain access to over 2,600 review questions!
            </h4>
            <div>
              {" "}
              Account Email:{" "}
              <input
                value={props.email}
                className="StripeElement"
                type="text"
                disabled={true}
                readOnly
              />
            </div>
            <div>
              {" "}
              Full Name:{" "}
              <input
                className="StripeElement"
                type="text"
                name="full_name"
                onChange={onFullNameChange}
                value={fullName}
              />
            </div>
            <div className="paymentFormField">
              {" "}
              Promo Code: <PromoCodeInput onCodeApplied={onPromoCodeApplied} />
            </div>
            <div>
              {" "}
              Card Information: <CardElement />{" "}
            </div>
            <div className="statementsToAcknowledgeField">
              {" "}
              {statementsToAcknowledge.map((statement) => (
                <label className="label" key={statement}>
                  <input
                    onInput={(e) => updateStatement(e.target)}
                    type="checkbox"
                    className="checkbox"
                    required
                  />
                  {statement}
                </label>
              ))}
            </div>
            <PriceView
              priceInCentsBeforeDiscount={props.paymentAmountCents}
              discountPercent={discountPercent}
            />
            {showProgressDialog ? (
              <button className="btn btn-primary btn-lg center-block">
                {" "}
                Processing...{" "}
              </button>
            ) : (
              <button
                className="btn btn-primary btn-lg center-block"
                onClick={submit}
              >
                {" "}
                Purchase{" "}
              </button>
            )}
          </>
        )}
        {alertMessage ? <Alert variant="danger">{alertMessage}</Alert> : null}
        {isSuccess ? (
          <div>
            <h3 className="text-center bold">Purchase Complete!</h3>

            <h2 className="text-center"> Get ready to ace your test!</h2>
            <a href="/" className="btn btn-primary btn-lg center-block">
              Get started now!
            </a>
          </div>
        ) : null}
      </div>
      <ProgressDialog isVisible={showProgressDialog} />
    </>
  )
}
