import { CategoryWithTopicsAndBookmarkCount } from "./CategoryWithTopicsAndBookmarkCount"
import { hasOwnProperty } from "../hasOwnProperty"
import { TopicWithBookmarkCount } from "./TopicWithBookmarkCount"
import { isArray, isNumber, isObject, isString } from "../TypeChecks"

function parseRowFromAnsweredQuestionsCountByTopic(
  data: object
): Record<number, number> {
  let result: Record<number, number> = {}

  for (const entry of Object.entries(data)) {
    //console.log("parsing entry", entry)
    const key = Number.parseInt(entry[0])
    const value = Number.parseInt(entry[1])

    result[key] = value
  }

  return result
}

function parseRowFromCategoriesWithTopicsAndBookmarkCounts(
  row: unknown
): CategoryWithTopicsAndBookmarkCount {
  if (!hasOwnProperty(row, "id")) {
    throw new Error(`Each category must have an "id" property.`)
  }
  const id = row.id

  if (!isNumber(id)) {
    throw new Error(`Each topic's "id" property must be a number.`)
  }

  if (!hasOwnProperty(row, "name")) {
    throw new Error(`Each category must have a "name" property.`)
  }
  const name = row.name

  if (!isString(name)) {
    throw new Error(`Each category's "name" property must be a string.`)
  }

  if (!hasOwnProperty(row, "topics")) {
    throw new Error(`Each category must have a "topics" property.`)
  }
  const topics = row.topics

  if (!isArray(topics)) {
    throw new Error(`Each category's "topics" property must be an array.`)
  }

  return {
    id,
    name,
    topics: parseTopicsWithBookmarkCounts(topics),
  }
}

function parseTopicsWithBookmarkCounts(
  rows: unknown[]
): TopicWithBookmarkCount[] {
  return rows.map(parseRowFromTopicsWithBookmarkCounts)
}

function parseRowFromTopicsWithBookmarkCounts(
  row: unknown
): TopicWithBookmarkCount {
  if (!hasOwnProperty(row, "topic_id")) {
    throw new Error(`Each topic must have a "topic_id" property.`)
  }
  const topicId = row.topic_id

  if (!isNumber(topicId)) {
    throw new Error(`Each topic's "topic_id" property must be a number.`)
  }

  if (!hasOwnProperty(row, "topic_name")) {
    throw new Error(`Each topic must have a "topic_name" property.`)
  }
  const topicName = row.topic_name

  if (!isString(topicName)) {
    throw new Error(`Each topic's "topic_name" property must be a string.`)
  }

  if (!hasOwnProperty(row, "bookmark_count")) {
    throw new Error(`Each topic must have a "bookmark_count" property.`)
  }
  const bookmarkCount = row.bookmark_count

  if (!isNumber(bookmarkCount)) {
    throw new Error(`Each topic's "bookmark_count" property must be a number.`)
  }

  return {
    topic_id: topicId,
    topic_name: topicName,
    bookmark_count: bookmarkCount,
  }
}

export const TaxonomyDataParser = {
  parseAnsweredQuestionsCountByTopic(jsonText: string): Record<number, number> {
    const responseBody = JSON.parse(jsonText)

    if (!isObject(responseBody)) {
      throw new Error("Response body must be a JSON object: " + jsonText)
    }

    if (!hasOwnProperty(responseBody, "success")) {
      throw new Error(
        "Response body must have a 'success' property: " + jsonText
      )
    }

    if (!responseBody.success) {
      throw new Error("Server returned a failure response: " + jsonText)
    }

    if (!hasOwnProperty(responseBody, "data")) {
      throw new Error("Response body must have a 'data' property: " + jsonText)
    }

    if (!isObject(responseBody.data)) {
      throw new Error(
        "Response body's 'data' property must be an object: " + jsonText
      )
    }

    return parseRowFromAnsweredQuestionsCountByTopic(responseBody.data)
  },

  parseCategoriesWithTopicsAndBookmarkCounts(
    jsonText: string
  ): CategoryWithTopicsAndBookmarkCount[] {
    const responseBody = JSON.parse(jsonText)

    if (!isObject(responseBody)) {
      throw new Error("Response body must be a JSON object: " + jsonText)
    }

    if (!hasOwnProperty(responseBody, "success")) {
      throw new Error(
        "Response body must have a 'success' property: " + jsonText
      )
    }

    if (!responseBody.success) {
      throw new Error("Server returned a failure response: " + jsonText)
    }

    if (!hasOwnProperty(responseBody, "data")) {
      throw new Error("Response body must have a 'data' property: " + jsonText)
    }

    if (!isArray(responseBody.data)) {
      throw new Error(
        "Response body's 'data' property must be an array: " + jsonText
      )
    }

    return responseBody.data.map(
      parseRowFromCategoriesWithTopicsAndBookmarkCounts
    )
  },
}
