export function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value)
}

export function isString(value: unknown): value is string {
  return typeof value === "string"
}

export function isObject(value: unknown): value is object {
  return typeof value === "object"
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number"
}

export function isBoolean(value: unknown): value is boolean {
  return value === true || value === false
}
