import React from "react"
import { CategoryWithTopicsAndBookmarkCount } from "../taxonomy/CategoryWithTopicsAndBookmarkCount"
import { answerQuestion } from "./answerQuestion"

export function HideOrRevealAnswerButton(props: {
  isAnswerDisplayed: boolean
  questionId: number
  onAnsweredQuestionsCountByTopicReceived(
    answeredQuestionsCountByTopic: Record<number, number>
  ): void
  onRevealAnswerClick(): void
  size: "smallOrSmaller" | "mediumOrLarger"
}) {
  function onRevealAnswerClick() {
    props.onRevealAnswerClick()
    answerQuestion(
      props.questionId,
      props.onAnsweredQuestionsCountByTopicReceived
    )
  }

  if (props.size === "mediumOrLarger") {
    return (
      <div
        className="col-0 col-sm-6 reveal_answer"
        onClick={onRevealAnswerClick}
      >
        <span className="revealAnswerIcon"></span>{" "}
        {props.isAnswerDisplayed
          ? "Click to Hide Answer"
          : "Click to Reveal Answer"}
      </div>
    )
  } else if (props.size === "smallOrSmaller") {
    return (
      <div
        className="col-10 reveal_answer_sm_screen"
        onClick={onRevealAnswerClick}
      >
        <span className="revealAnswerIcon"></span>{" "}
        {props.isAnswerDisplayed ? "Hide Answer" : "Reveal Answer"}
      </div>
    )
  } else {
    throw new Error("Unknown size: " + props.size)
  }
}
