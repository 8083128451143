import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"

import { KlimekReviewsImageUrls } from "./KlimekReviewsImageUrls"
import { CategoryWithTopicsAndBookmarkCount } from "./taxonomy/CategoryWithTopicsAndBookmarkCount"
import { TopicWithBookmarkCount } from "./taxonomy/TopicWithBookmarkCount"

export default function CategoriesAndTopics(props: {
  categories: CategoryWithTopicsAndBookmarkCount[] | null
  images: KlimekReviewsImageUrls
  isVisible: boolean
  onCloseClick(): void
  user_is_premium: boolean
}) {
  const [expandCategoryID, setExpandCategoryID] = useState<number | null>(null)

  function onCategoryClick(category: CategoryWithTopicsAndBookmarkCount) {
    if (expandCategoryID == category.id) {
      setExpandCategoryID(null)
    } else {
      setExpandCategoryID(category.id)
    }
  }

  function onTopicClick(topic: TopicWithBookmarkCount) {
    window.location.href = `/topic/${topic.topic_id}?show_only_bookmarked=false`
  }

  function renderAnsweredQuestionsCount(
    topic: TopicWithBookmarkCount
  ): JSX.Element | null {
    if (topic.answeredQuestionsCount === undefined) {
      return null
    } else {
      return (
        <>
          {" "}
          ( {topic.answeredQuestionsCount}{" "}
          <img
            className="answeredQuestionsCountCheckmarkIcon"
            src={props.images.checkmarkTenPixels}
            alt="answered questions"
          />{" "}
          )
        </>
      )
    }
  }

  function renderCategories() {
    return (
      props.categories &&
      props.categories.map((category, index) => {
        return (
          <div key={index}>
            <div
              className="modal_link categories_text"
              onClick={() => onCategoryClick(category)}
            >
              ‣ {category.name}
            </div>
            {expandCategoryID == category.id ? (
              <ul>
                {category.topics.map(
                  (topic: TopicWithBookmarkCount, topicIndex: React.Key) => {
                    return (
                      <li
                        className="modal_link"
                        onClick={() => onTopicClick(topic)}
                        key={topicIndex}
                      >
                        {topic.topic_name}
                        {renderAnsweredQuestionsCount(topic)}
                      </li>
                    )
                  }
                )}
              </ul>
            ) : null}
          </div>
        )
      })
    )
  }

  return (
    <>
      <Modal
        centered={true}
        dialogClassName="modal_size_increase"
        backdrop="static"
        show={props.isVisible}
        onHide={() => props.onCloseClick()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="bold">Categories and Topics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{renderCategories()}</div>
        </Modal.Body>
      </Modal>
    </>
  )
}
