import React, { MouseEvent } from "react"

export default function FrontPageSmallBox(props: {
  title: string
  description1: string
  description2: string
  buttonLink?: string
  buttonText: string
  buttonClickHandler?(event: MouseEvent): void
}) {
  return (
    <>
      <div className="feature">
        <br />
        <h2 className="bold">{props.title}</h2>
        <br />
        <h4>{props.description1}</h4>
        <h4>{props.description2}</h4>
        <br />
        <a
          onClick={props.buttonClickHandler}
          href={props.buttonLink ? props.buttonLink : "#"}
          className="btn btn-primary btn-lg"
        >
          {props.buttonText}
        </a>
        <br />
      </div>
    </>
  )
}
