import React from "react"

function WelcomeMessage(props: {
  current_user: { first_name: string; is_admin: boolean; last_name: string }
}) {
  if (props.current_user.is_admin) {
    return (
      <div className="welcome_name">
        Welcome,{" "}
        <a href="/manage">
          {props.current_user.first_name} {props.current_user.last_name}!
        </a>
      </div>
    )
  } else {
    return (
      <div className="welcome_name">
        Welcome, {props.current_user.first_name} {props.current_user.last_name}!{" "}
      </div>
    )
  }
}

export function CurrentUserMenu(props: {
  current_user: { first_name: string; is_admin: boolean; last_name: string }
}) {
  return (
    <>
      <WelcomeMessage current_user={props.current_user} />
      <a
        href="/users/sign_out"
        data-method="delete"
        className="btn btn-primary btn-lg"
      >
        Sign Out
      </a>
    </>
  )
}
