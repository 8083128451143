import React, { Key, ReactChild, ReactFragment, ReactPortal } from "react"

export default function AnswerDisplay(props: {
  answerDisplayed: boolean
  answers: { id: Key; name: unknown }[]
}) {
  function renderAnswers() {
    if (props.answerDisplayed) {
      return props.answers.map(
        (answer: {
          id: Key
          name: boolean | ReactChild | ReactFragment | ReactPortal
        }) => {
          return (
            <div key={answer.id} className="answer">
              {answer.name}
            </div>
          )
        }
      )
    } else {
      return null
    }
  }

  return (
    <>
      <div className="answers italic">
        {""}
        {props.answers ? renderAnswers() : "Loading..."}
      </div>
    </>
  )
}
