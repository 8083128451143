import { DateTime } from "luxon"
import React, { FormEvent, ReactElement, useState } from "react"
import { Alert } from "react-bootstrap"

export default function CreatePromoCodesForm(props: unknown) {
  const [alertMessage, setAlertMessage] = useState<ReactElement | null>(null)
  const [alertType, setAlertType] = useState<"success" | "danger" | undefined>(
    undefined
  )

  function getDateLimit() {
    const date = new Date()
    return DateTime.fromJSDate(date).toISODate()
  }

  async function createPromoCodeFormSubmit(event: FormEvent) {
    event.preventDefault()
    console.log("createPromoCodeFormSubmit")
    const formElement = event.target
    if (!(formElement instanceof HTMLFormElement)) {
      throw new Error(
        "Form element is not an instance of HTMLFormElement: " + formElement
      )
    }
    const data = new FormData(formElement)
    const startDateInput = formElement.querySelector("#promoCodeStartDate")
    if (!(startDateInput instanceof HTMLInputElement)) {
      throw new Error(
        "Start date input element is not an instance of HTMLInputElement: " +
          startDateInput
      )
    }
    const startDate = startDateInput.value
    const startTimestamp = DateTime.fromISO(startDate).toSeconds()
    data.append("start_timestamp", startTimestamp.toString())

    const request = new Request("/api/promo_codes/create", {
      method: "POST",
      body: data,
    })
    const response = await fetch(request)
    const jsonResponse = await response.json()
    if (jsonResponse.success) {
      formElement.reset()
      setAlertMessage(<>New promo code created</>)
      setAlertType("success")
    } else {
      setAlertMessage(<>{jsonResponse.message}</>)
      setAlertType("danger")
    }
  }

  return (
    <>
      <div className="col-10 offset-1 front_page_align">
        <h1 className="bold text-center"> Manage Promo Codes</h1>
        <hr />
        {alertMessage ? (
          <Alert variant={alertType}>{alertMessage}</Alert>
        ) : null}
        <form onSubmit={createPromoCodeFormSubmit}>
          <div className="mb-3">
            <label htmlFor="promoCode" className="form-label">
              Promo Code
            </label>
            <input
              required
              className="form-control required"
              id="promoCode"
              name="promo_code"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="promoCodeStartDate" className="form-label">
              Promo Code Start Date
            </label>
            <input
              required
              type="date"
              className="form-control required"
              id="promoCodeStartDate"
              name="start_date"
              min={getDateLimit()}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="promoCodeEndDate" className="form-label">
              Promo Code Duration
            </label>
            <input
              required
              type="integer"
              className="form-control required"
              id="promoCodeEndDate"
              name="how_many_days"
              aria-describedby="daysHelp"
            />
            <div id="daysHelp" className="form-text">
              Enter duration in days
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="promoCodeDiscountPercentage" className="form-label">
              Promo Code Discount Percentage
            </label>
            <input
              required
              type="number"
              className="form-control required"
              id="promoCodeDiscountPercentage"
              name="discount_percent"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="promoCodeLimit" className="form-label">
              Promo Code Usage Limit
            </label>
            <input
              type="number"
              className="form-control"
              id="promoCodeLimit"
              name="usage_limit"
              aria-describedby="limitHelp"
            />
            <div id="limitHelp" className="form-text">
              If there is no limit, leave it blank
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Create
          </button>
        </form>
      </div>
    </>
  )
}
